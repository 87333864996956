.page-header {
    position: fixed;
    left: 0;
    top: 0;
    height: 110px;
    z-index: 999;
    .main-nav {
        width: 100%;
        ul {
            list-style-type: none;
            display: inline-block;
            margin: 0;
            padding: 0;
            display: flex;
            width: 600px;
            li {
                position: relative;
                width: 180px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover {
                    .close-border {
                        background: #00F3F3; }
                    a {
                        position: relative;
                        z-index: 4;
                        color: #fff; } }
                &:nth-child(1) {
                    width: 180px;
                    height: 100px;
                    color: #fff;
                    position: relative;
                    .close-border {
                        width: 100%;
                        height: 100%;
                        background: #000;
                        color: #fff;
                        -webkit-transform: skew(-20deg);
                        -moz-transform: skew(-20deg);
                        -o-transform: skew(-20deg);
                        position: absolute;
                        left: -15%; }
                    .arrow-close {
                        width: 70px;
                        height: 70px;
                        border: 5px solid #fff;
                        z-index: 3;
                        position: absolute;
                        top: 50%;
                        left: 40%;
                        margin-right: -50%;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        transition: all .3s ease;
                        &:hover {
                            border: none;
                            background: #00F3F3;
                            .arrow {
                                border-right: 30px solid #000; } }
                        .arrow {
                            position: relative;
                            z-index: 3;
                            width: 0;
                            right: 5px;
                            height: 0;
                            border-top: 20px solid transparent;
                            border-right: 30px solid #fff;
                            border-bottom: 20px solid transparent;
                            transition: all .3s ease; } } }

                .close-border {
                    width: 100%;
                    height: 100%;
                    background: transparent;
                    -webkit-transform: skew(-20deg);
                    -moz-transform: skew(-20deg);
                    -o-transform: skew(-20deg);
                    position: absolute;
                    border: 5px solid #000;
                    top: 1px;
                    left: -8%;
                    transition: all .3s ease;
                    background: #EFEFEF; }
                a {
                    color: #000;
                    text-decoration: none;
                    font-weight: 700;
                    font-size: 22px;
                    width: 120px;
                    text-align: center;
                    transition: all .3s ease;
                    position: relative;
                    z-index: 4; } } } } }

.about-component {
    padding-top: 180px;
    overflow-x: hidden;
    overflow-y: hidden;
    .about-container {
        display: flex;
        flex-direction: row;

        .text-composition {
            padding-left: 150px;
            width: 30%;
            position: relative;

            .container-absolute {
                position: absolute;
                top: 20%;
                right: 0%;

                .bg-wrapp {
                    width: 363px;
                    height: 238px;
                    background: url('../../img/bg11.png');
                    position: absolute;
                    z-index: 10;
                    &:hover {
                        background: url('../../img/bg11Active.png'); } }
                .white {
                    color: #fff; }
                .h2 {
                    font-size: 96px;
                    position: relative;
                    left: 100%;
                    top: 20%;
                    font-weight: 700;
                    z-index: 11; }
                .h2-second {
                    font-size: 96px;
                    position: relative;
                    left: 70%;
                    top: 0;
                    font-weight: 700;
                    z-index: 11; } }
            .logo-about {
                .h1 {
                    font-size: 48px;
                    font-weight: 700;
                    padding-bottom: 10px;
                    border-bottom: 3px solid #000; }
                span {
                    font-size: 120px;
                    color: #00F3F3;
                    font-weight: bold; } }
            .second-logo {
                max-width: 320px;
                margin-bottom: 30px;
                .h3 {
                    font-size: 48px;
                    color: #000; } }
            .list-price {
                ul {
                    list-style-type: none;
                    padding: 0;
                    li {
                        margin-bottom: 30px;
                        .h5 {
                            font-size: 22px;
                            padding-bottom: 7px;
                            position: relative;
                            max-width: 90px;
                            margin-bottom: 10px;
                            &::after {
                                content: '';
                                width: 100%;
                                height: 3px;
                                background-color: #00F3F3;
                                left: 0;
                                bottom: 0;
                                position: absolute; } }
                        p {
                            font-weight: 400;
                            font-size: 18px;
                            max-width: 250px; } } } } }
        .animation-composition {
            width: 70%;
            position: relative;
            top: -180px;
            .bg-lock {
                position: absolute;
                width: calc(100vw - 17%);
                background: #1E1E1E;
                transform: skew(-15deg);
                right: -605px;
                top: -200px;
                height: 1160px;
                z-index: 11; }
            .animation-wrapper {
                position: absolute;
                z-index: 12;
                left: 40%;
                top: 8%;
                img {
                    width: 520px; } } } } }

.learn-plan {
    position: relative;
    overflow: hidden;
    height: 1080px;
    .bg-lock {
        position: absolute;
        width: calc(100vw - 32%);
        background: #1E1E1E;
        height: 1093px;
        right: 5.1%;
        top: 0;
        transform: skew(-15deg);
        opacity: .989;
        z-index: 9; }
    .container-learn-plan {
        position: absolute;
        z-index: 10;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50, -50);
        padding-top: 80px;
        width: 500px;
        .top-line-wrapper {
            text-align: center;
            color: #00F3F3; }
        .list-price-wrapper {
            position: relative;
            ul {
                list-style-type: none;
                padding: 0;
                li {
                    position: relative;
                    margin-bottom: 20px;
                    &:nth-child(2) {
                        left: -5%; }
                    &:nth-child(3) {
                        left: -10%; }
                    &:nth-child(4) {
                        left: -15%; }
                    &:nth-child(5) {
                        left: -20%; }
                    &:nth-child(6) {
                        left: -25%; }
                    &:nth-child(7) {
                        left: -30%; }
                    .h3 {
                        color: #EFEFEF;
                        font-size: 28px;
                        position: relative;
                        &::after {
                            position: absolute;
                            content: "";
                            width: 100%;
                            height: 2px;
                            background: #fff;
                            bottom: 0;
                            left: -2.9%; } }
                    p {
                        color: #fff; }

                    .lock-wrapper {
                        border-radius: 50%;
                        position: absolute;
                        left: -70px;
                        top: 4%;
                        width: 50px;
                        height: 50px;
                        z-index: 15;
                        border: 3px solid #fff;
                        .in-lock-wrapper {
                            position: relative;
                            background: #fff;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            top: 50%;
                            left: 50%;
                            margin-right: -50%;
                            transform: translate(-50%, -50%); } } } } } } }
.studens-work {
    position: relative;
    overflow: hidden;
    height: 920px;
    padding-top: 25px;
    .bg-lock {
        position: absolute;
        width: calc(100vw - 32%);
        background: #1E1E1E;
        height: 1093px;
        left: 12%;
        top: 0;
        transform: skew(-15deg);
        opacity: .989;
        z-index: 9; }
    .top-line {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .h2 {
            position: relative;
            text-align: right;
            z-index: 10;
            width: 311px;
            font-size: 32px; } }
    .image-controller {
        max-width: 1380px;
        position: relative;
        z-index: 12;
        position: absolute;
        top: 40%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        img {
            width: 100%; } } }
@media screen and ( max-width: 1620px ) {
    .about-component .about-container .animation-composition .bg-lock {
        width: calc(100vw - 12%); }
    .studens-work .bg-lock {
        width: calc(100vw - 32%);
        left: 9%; }
    .last-screen .bg-lock {
        width: calc(100vw - 34.4%);
        right: 36%; } }
@media screen and ( max-width: 1450px ) {
    .about-component .about-container .animation-composition .bg-lock {
        width: calc(100vw - 7%); }
    .studens-work .bg-lock {
        width: calc(100vw - 31%);
        left: 6%; }
    .last-screen .bg-lock {
        width: calc(100vw - 34.4%);
        right: 39%; }
    .learn-plan .bg-lock {
        width: calc(100vw - 31%); }
    .about-component .about-container .text-composition .container-absolute {
        display: none; } }
