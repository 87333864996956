.last-screen {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: auto;

    .last-screen_container {
        position: relative;
        height: 500px;
        min-width: 50%;
        display: flex;
        justify-content: flex-end;
        position: relative;
        padding: 76px 50px 76px 0px;
        img {
            width: 400px;
            height: 500px; } }
    .bg-lock {
        position: absolute;
        background-color: #212121;
        transform: skew(-15deg);
        z-index: 1;
        width: calc(100vw - 34.4%);
        height: 700px;
        background-size: cover;
        right: 31%;
        padding: 20px;
        .text-composition {
            width: 500px;
            padding-top: 10px;
            position: relative;
            z-index: 2;
            right: 5px;
            transform: skew(15deg);
            ul {
                list-style: none; }
            li {
                max-width: 72%;
                margin: 35px 0px 35px 102px;
                h1 {
                    color: #00F3F3;
                    font-size: 40px;
                    font-weight: 700; }
                h2 {
                    color: #EFEFEF;
                    font-size: 30px;
                    font-weight: 700; }
                p {
                    color: #EFEFEF;
                    font-size: 20px;
                    font-weight: 700; }
                span {
                    color: #00F3F3; } } } } }

.sertificat-compostion {
    position: absolute;
    bottom: -10%;
    left: 40%;
    .btn-sertificat {
        width: 80px;
        height: 80px;
        background: #000;
        border-radius: 50%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .3s ease;
        &:hover {
            background: #00F3F3; }
        img {
            width: 100%;
            height: 100%; } } }
::-webkit-scrollbar {
    width: 0px;
    background: rgba(255, 255, 255, 0.0); }

.pop-up {
    position: absolute;
    z-index: 9999;
    //bottom: 250px
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
.pop-up-form-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 16%;
    height: 75%;
    background-color: transparent;
    z-index: 99999;
    left: 57%;
    top: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    //padding: 30px 16px
    animation: opacityIn .3s ease-in-out forwards;
    border-radius: 5px;
    overflow: scroll;
    .image-sert {
        margin: 5px 0;
        cursor: pointer;
        img {
            width: 250px;
            height: 150px; } } }
.pop-up-sert {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, .7); }
.pop-up-form-wrapper-sert {
    position: relative;
    width: 40%;
    height: 50%;
    //background-color: #F4F4F4
    z-index: 99999;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 30px 16px;
    animation: opacityIn .3s ease-in-out forwards;
    border-radius: 5px;
    img {
        cursor: pointer;
        width: 100%;
        height: 100%; } }

@keyframes opacityIn {
    0% {
        opacity: 0; }
    50% {
        opacity: 0.5; }
    100% {
        opacity: 1; } }
@media screen and ( max-width:  1600px) {
    .last-screen .bg-lock {
        width: calc(100vw - 34.4%);
        right: 36%; } }
@media screen and ( max-width:  1450px) {
    .last-screen .bg-lock {
        width: calc(100vw - 31.4%);
        right: 39%; } }
